let menuItems = {}
menuItems.links = [
  {
    title: "Burgfestspiele",
    url: "/burgfestspiele/",
    venue: "burgfestspiele",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/1440x961/e998eb50b2/burgbadvilbel.jpg",
    },
  },
  {
    title: "Open Air Kino",
    url: "/open-air-kino/",
    venue: "open-air-kino",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/1778x1181/48aacaf1ec/open-air-kino_1.jpg",
    },
  },
  {
    title: "Stadtbibliothek",
    url: "/stadtbibliothek/",
    venue: "stadtbibliothek",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/1772x1181/50a2d7115e/bv-biblioth-11-2013-245.jpg",
    },
  },
  {
    title: "Theater Alte Mühle",
    url: "/theater-alte-muehle/",
    venue: "theater-alte-muehle",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/3024x2012/7bebce298e/altemuehle.jpg",
    },
  },
  {
    title: "Kino Alte Mühle",
    url: "/kino-alte-muehle/",
    venue: "kino-alte-muehle",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/3024x2012/7bebce298e/altemuehle.jpg",
    },
  },
  {
    title: "Kulturforum",
    url: "/kulturforum/",
    venue: "kulturforum",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/1815x1340/6843c3f696/kultur-und-sportforum_1.jpg",
    },
  },
  {
    title: "Kultur & Natur",
    url: "/kultur-natur/",
    venue: "kultur-natur",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/6000x4000/c2568ea8a0/dsc00477.JPG",
    },
  },
  {
    title: "Museen",
    url: "/museen/",
    venue: "museen",
    image: {
      filename: "https://a.storyblok.com/f/92205/995x491/3e1180333c/bb1.jpg",
    },
  },
  {
    title: "Feste & Märkte",
    url: "/feste-und-maerkte/",
    venue: "feste-und-maerkte",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/2048x1536/10ef4cb6df/img_0694.jpg",
    },
  },
  {
    title: "VILCO Stadthalle",
    url: "/vilco/",
    venue: "vilco",
    image: {
      filename:
        "https://a.storyblok.com/f/92205/3000x1568/849998f8d4/171010_perspektive_01_va-bearb.png",
    },
  },
]

export default menuItems
