import React, { useRef, useEffect } from "react"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import HeroBlock from "gatsby-theme-yum-components/src/components/blocks/HeroBlock"

import { Helmet } from "react-helmet"
import eventImage from "../images/event.jpg"
import { gsap } from "gsap"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"
import { faMapPin, faTag, faInfoSquare } from "@fortawesome/pro-solid-svg-icons"
import menuItems from "../components/constants/menuItems"

const useKenBurns = () => {
  const imageRef = useRef(null)

  useEffect(() => {
    gsap.to(imageRef.current, {
      scale: 1.2,
      duration: 15,
      ease: "power1.out",
    })
  }, [])
  return { imageRef }
}

const EventPage = ({ venue, location }) => {
  const { imageRef } = useKenBurns()
  const data = {}
  return (
    <Layout venue={venue} menuItems={menuItems} location={location}>
      <Helmet
        bodyAttributes={{
          class: "theme-burgfestspiele font-body antialiased",
        }}
      />
      <HeroBlock
        block={{
          venue: "burgfestspiele",
          image: { filename: eventImage },
          headline: "Lars Reichow - „Ich“",
          kicker: "Vorpremiere",
        }}
      />
      {false && (
        <section className="relative overflow-hidden bg-red-500">
          <div className="w-full overflow-hidden h-screen-50" ref={imageRef}>
            <Image
              image={eventImage}
              alt={data.alt}
              title={data.title}
              className="block object-contain w-full h-full"
            />
          </div>
        </section>
      )}
      <BlockWrapper blockPadding="p-0" block={{}}>
        <div className="flex flex-row flex-wrap -mx-grid">
          <div className="w-full md:w-1/2 px-grid">
            <div className="mt-4">
              <span className="mr-6">
                <FontAwesomeIcon
                  icon={faMapPin}
                  size="1x"
                  className="mr-2 text-gray-600"
                />{" "}
                Theater Alte Mühle
              </span>
              <span className="mr-6">
                <FontAwesomeIcon
                  icon={faTag}
                  size="1x"
                  className="mr-2 text-gray-600"
                />
                Musical
              </span>
              <span>
                <FontAwesomeIcon
                  icon={faInfoSquare}
                  size="1x"
                  className="mr-2 text-gray-600"
                />{" "}
                FSK-12
              </span>
            </div>

            <div className="py-4 font-bold ">
              Das neue Bühnenprogramm von Lars Reichow ist mehr als ein Blick in
              den Spiegel, eine Scheitelkorrektur oder das Richten einer
              Strähne.
            </div>
          </div>
          <div className="w-full md:w-1/2 px-grid">
            <div className="flex w-full pt-4 pb-4 border">
              <div className="flex-none w-24 pr-8 text-right border-r-2 border-gray-400">
                <div className="text-3xl font-bold">1</div>
                <div className="text-base">Sept</div>
              </div>
              <div className="flex-none px-8 ">
                Freitag
                <br />
                14:30-15:00 Uhr (2 Stunden)
              </div>

              <div className="flex-none px-8">
                <div className="text-xl">ab 4,- €</div>
                <a
                  href="/"
                  className="block px-4 py-2 mt-2 bg-gray-300 rounded hover:bg-gray-500"
                >
                  Ticket bestellen
                </a>
              </div>
            </div>
            <a
              href="/"
              className="block w-full p-4 pb-16 text-center hover:text-primary"
            >
              Alle Termine anzeigen
            </a>
          </div>
          <div className="w-full md:w-1/3 px-grid">
            <Image
              className="w-full h-auto"
              image={{ filename: "https://picsum.photos/600/900" }}
            />
          </div>
          <div className="w-full md:w-2/3 px-grid">
            <Richtext
              text={`Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.
                
Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.`}
            />
          </div>
        </div>
      </BlockWrapper>

      <BlockWrapper
        block={{ headline: "Weitere Termine" }}
        blockPadding="py-block"
        centerHeadline="true"
        showHeadline="true"
      >
        {[1, 2, 3, 3, 4, 6, 8, 10, 11, 14, 26].map((i) => (
          <div
            className="flex w-full pt-4 pb-4 mx-auto mt-4 border md:w-1/2"
            key={i}
          >
            <div className="flex-none w-24 pr-8 text-right border-r-2 border-gray-400">
              <div className="text-3xl font-bold">{i}</div>
              <div className="text-base">Sept</div>
            </div>
            <div className="flex-none px-8 text-sm text-gray-700">
              Freitag
              <br />
              14:30-15:00 Uhr (2 Stunden)
            </div>

            <div className="flex-none px-8">
              <div className="text-xl">ab 4,- €</div>
              <a
                href="/"
                className="block px-4 py-2 mt-2 bg-gray-300 rounded hover:bg-gray-500"
              >
                Ticket bestellen
              </a>
            </div>
          </div>
        ))}
      </BlockWrapper>
    </Layout>
  )
}

export default EventPage
